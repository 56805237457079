// A curried function ( https://medium.com/javascript-scene/curry-and-function-composition-2c208d774983 )
// to apply params to a url
// see tests for use: src/js/helpers/__tests__/addParametersToURLs.test.js

const addParametersToURLs = url => (params = {}) => {
  const { hostname, protocol } = window.location;

  // create a URL object
  const returnUrl = new URL(url, `${protocol}//${hostname}`);

  // add the new parameters to the query string
  const keys = Object.keys(params);
  keys.forEach((key) => {
    returnUrl.searchParams.set(`${key}`, params[key]);
  });
  // give it back in all its glory
  return returnUrl.toString();
};

export default addParametersToURLs;
