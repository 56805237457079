declare global {
  interface Window {
    Rollbar: any;
  }
}

const hasRollbar = !!window.Rollbar;

const warning = (str: string, data: any = '') => {
  if (hasRollbar) {
    window.Rollbar.warning(str, data);
  } else {
    console.log('Warning:', str, data);
  }
};

const critical = (str: string, data: any = '') => {
  if (hasRollbar) {
    window.Rollbar.critical(str, data);
  } else {
    console.log('Critical:', str, data);
  }
};

const logger = {
  warning: warning,
  critical: critical,
};

export default logger;
