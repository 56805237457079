import axios from 'axios';
import storageAvailable from '../../../helpers/storage_available';

async function reActivated(response) {
  const { affiliate } = window._sr;
  affiliate.anticipated_revenue_per_offerer = 0;
  affiliate.anticipated_revenue_per_seeker = 0;
  const { uuid } = response.session;
  const user_type = response.session.usertype_new == 'haveashare' ? 'offerer' : 'seeker';
  const reEngaged = {
    date: Date.now(),
    affiliate: affiliate,
  };
  if (storageAvailable('localStorage')) {
    window.localStorage.setItem('re-engaged', JSON.stringify(reEngaged));
  }
  if (!affiliate.id) {
    return true;
  }

  // I wonder if we get this value back from the session now - worth a check
  try {
    const arpuResponse = await axios.post(`/api/users/${uuid}/reactivate`, {
      affiliate: {
        id: affiliate.id,
      },
    });
    const { amount } = arpuResponse.data.data.arpu;
    // do we really care about the user type???
    // I think registered and re-egnaged user type tracking is taken from _sr.customer.user_type
    if (user_type == 'offerer') {
      affiliate.anticipated_revenue_per_offerer = (amount / 100).toFixed(2);
    } else {
      affiliate.anticipated_revenue_per_seeker = (amount / 100).toFixed(2);
    }
    reEngaged.affiliate = affiliate;
    if (storageAvailable('localStorage')) {
      window.localStorage.setItem('re-engaged', JSON.stringify(reEngaged));
    }
    return true;
  } catch (error) {
    return error;
  }
}

function registered(response) {
  if (!storageAvailable('localStorage')) {
    return false;
  }
  const { affiliate } = window._sr;
  const {
    arpu,
    session: { usertype_new: userTypeNew },
  } = response;
  if (userTypeNew && arpu) {
    const userType = userTypeNew == 'haveashare' ? 'offerer' : 'seeker';
    if (userType == 'offerer') {
      affiliate.anticipated_revenue_per_offerer = arpu;
    } else {
      affiliate.anticipated_revenue_per_seeker = arpu;
    }
  }
  const registeredObject = JSON.stringify({
    date: Date.now(),
    affiliate: affiliate,
  });
  window.localStorage.setItem('registered', registeredObject);
  return true;
}

export default {
  reActivated,
  registered,
};
