import logger from '../../global/logger';
import trackEvent from '../../helpers/gtm/eventPush';
import get from '../../helpers/data_layer/get';

const facebookReporting = (sdk: any) => {
  const label = get('page.name');
  if (sdk && typeof sdk.login === 'function') {
    // Track success in GA
    trackEvent({
      action: 'facebook_sdk_loaded',
      category: 'login',
      label: label,
    });
  } else {
    // Track failure in GA
    trackEvent({
      action: 'facebook_sdk_not_loaded',
      category: 'login',
      label: label,
    });
    // Log with Rollbar
    logger.warning('FB SDK – login was attempted but sdk had not loaded');
  }
};

export default facebookReporting;
