import tracking from './tracking';
import addParametersToURLS from '../../../helpers/addParametersToURLs';

function success(response) {
  if (response.session) {
    const { user_reactivated_after_3months, justregistered } = response.session;
    if (user_reactivated_after_3months) {
      return tracking
        .reActivated(response)
        .then(() => window.location.assign(response.redirect_url));
    }
    if (response.justregistered) {
      tracking.registered(response);
      const addParamsToRegisteredUrl = addParametersToURLS(
        response.redirect_url,
      );
      const redirectURL = addParamsToRegisteredUrl({ facebook_signup: 1 });
      return window.location.assign(redirectURL);
    }
  }
  return window.location.assign(response.redirect_url);
}

function failure(error) {
  if (error.data && error.data.redirect_url) {
    //  we know what to do... pass them along
    return window.location.replace(error.data.redirect_url);
  }
  // Else we don't know what has gone wrong!
  const errorMessage = error.message ? `: ${error.message}` : '';
  console.warn(
    `There has been an error connecting with facebook_connect.pl${errorMessage}`,
  );
  throw new Error(error);
}

export default {
  success,
  failure,
};
