import '../../../sass/components/auth/user_auth.scss';
import '../../../sass/components/forms.scss';
import '../../../sass/components/auth/sign_in.scss';
import '../../../sass/components/auth/registration_links_panel.scss';
import trackEvent from '../../helpers/gtm/eventPush';
import { fetchData } from '../../helpers/base_data_fetcher';
import facebookLogin from '../../services/facebookLogin';
import '../../../sass/components/modal_dialog.scss';
import '../../../sass/components/message_box.scss';
import set_up_modal_dialog from '../Modal/dialog';

const initiateFacebookLogin = () => {
  // this should be made so it only runs once
  Promise.all([
    fetchData('page'),
    fetchData('site'),
    fetchData('affiliate'),
    fetchData('config'),
  ]).then(([page, site, affiliate, config]) => {
    facebookLogin.initialiseApp({ language: config.facebook.app_locale });
    const facebookButtons = document.querySelectorAll(
      '.user-auth [data-facebook-connect]',
    );

    facebookButtons.forEach((button) =>
      button.addEventListener('click', (event) => {
        event.preventDefault();
        facebookLogin.login(
          {
            loginfrom_url: page.loginReturnPath,
            referred_by: affiliate.id,
          },
          site.sub_dir,
        );
      }),
    );
  });
};

const initiateFacebookFallback = () => {
  const noFacebookButton = document.querySelector(
    '.user-auth [data-facebook-connect-unavailable]',
  );

  if (noFacebookButton) {
    noFacebookButton.addEventListener('click', (event) => {
      event.preventDefault();
      noFacebookButton.style.display = 'none';
      document.querySelector('.sign-in__or').style.display = 'none';
      document
        .querySelectorAll('.sign-in__facebook-unavailable-message')
        .forEach((el) => (el.style.display = 'block'));
    });
  }
};

const sign_in_modal = document.getElementById('auth-modal-dialog');

if (sign_in_modal) {
  const { open_modal_dialog, close_modal_dialog } = set_up_modal_dialog({
    dialog_element: sign_in_modal,
    non_modal_dialog_as_modal: true,
  });
  document.querySelectorAll('.show-user-auth-popup').forEach((link) => {
    link.onclick = (event) => {
      event.preventDefault();
      open_modal_dialog({ initiating_element: link });
      initiateFacebookLogin();
    };
  });

  const password_reminder = document.getElementById('forgotten-password-link');
  password_reminder.addEventListener('click', () => {
    trackEvent({
      category: 'login',
      action: 'login_forgot_password',
    });
  });

  const rememberMeCheckBox = document.getElementById('remember_me_checkbox');
  const hiddenCheckBox = document.getElementsByName('remember_me')[0];
  if (rememberMeCheckBox && hiddenCheckBox) {
    rememberMeCheckBox.addEventListener('click', (event) => {
      if (event.currentTarget.checked) {
        return (hiddenCheckBox.value = 'Y');
      }
      return (hiddenCheckBox.value = 'N');
    });
  }

  initiateFacebookFallback();
}
