import axios from 'axios';

async function login(extraParams, subDirectoy = 'flatshare') {
  const response = await axios.post(
    `/${subDirectoy}/facebook_connect.pl`,
    null,
    {
      params: {
        format: 'json',
        custom_password_required: 1,
        ...extraParams,
      },
    },
  );
  if (!response.data.success) {
    throw response;
  }
  return response.data;
}

export default {
  login,
};
