import facebookConnect from './components/facebookConnect';
import api from './components/api';
import handleApiResponse from './components/handleApiResponse';
import sdkReporting from './sdkReporting';

function initialiseApp({ language = 'en_GB' } = {}) {
  return facebookConnect.loadSDK(language);
}

function attemptLogin(sdk, params, subDirectory) {
  return api
    .login(params, subDirectory)
    .then((response) => handleApiResponse.success(response))
    .catch((error) => handleApiResponse.failure(error));
}

async function isLoggedIn(sdk) {
  if (!sdk) {
    sdk = await facebookConnect.getSDK();
  }
  const response = await facebookConnect.getLoginStatus(sdk);
  if (response.status === 'connected') {
    return true;
  }
  return false;
}

async function login(params, subDirectory = 'flatshare') {
  const sdk = await facebookConnect.getSDK();
  sdkReporting(sdk);
  if (sdk) {
    return facebookConnect
      .login(sdk)
      .then((loginResponse) => attemptLogin(sdk, params, subDirectory));
  }
  // should we instead be returning Promise.reject(new Error('Cannot log user in as Facebook SDK has not been loaded'))?
  return console.log('Cannot log user in as Facebook SDK has not been loaded');
}

export default {
  initialiseApp,
  login,
  isLoggedIn,
};
