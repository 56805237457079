/* Code for generic SR modal dialog behaviour */

/*
a hack to enable the clearing of global event listeners when testing
(or else they persist across tests)
*/
let is_in_test_mode = false;
let element_to_restore_focus_to_on_close;

function set_initiating_element(element) {
  if (element) {
    element_to_restore_focus_to_on_close = element;
  }
}

function restore_focus_to_initiating_element() {
  if (element_to_restore_focus_to_on_close) {
    element_to_restore_focus_to_on_close.focus();
  }
  element_to_restore_focus_to_on_close = undefined;
}

function get_all_sibling_elements(element, parent) {
  const children = [...parent.children];
  return children.filter((child) => child !== element);
}

function set_sibling_elements_to_inert(dialog_element) {
  const sibling_elements = get_all_sibling_elements(
    dialog_element,
    dialog_element.parentNode,
  );
  sibling_elements.forEach((element) => element.setAttribute('inert', ''));
}

function unset_sibling_elements_to_inert(dialog_element) {
  const sibling_elements = get_all_sibling_elements(
    dialog_element,
    dialog_element.parentNode,
  );
  sibling_elements.forEach((element) => element.removeAttribute('inert'));
}

function add_attributes_to_unsupported_dialog(dialog) {
  dialog.classList.add('dialog-polyfill');
  dialog.setAttribute('role', 'dialog');
  dialog.setAttribute('aria-modal', true);
  if (dialog.open) {
    dialog.classList.add('dialog-polyfill--open');
  }
}

function freeze_dcoument() {
  document.body.classList.add('prevent-scroll');
}

function unfreeze_document() {
  document.body.classList.remove('prevent-scroll');
}

function create_dialog_polyfill_mask() {
  let overlay_mask = document.getElementById('dialog-polyfill-mask');
  if (overlay_mask) {
    return overlay_mask;
  }
  overlay_mask = document.createElement('div');
  overlay_mask.id = 'dialog-polyfill-mask';
  overlay_mask.classList.add('dialog-polyfill-mask');
  document.body.appendChild(overlay_mask);
  return overlay_mask;
}

function check_if_dialog_element_is_supported(dialog) {
  if (
    dialog.tagName == 'DIALOG' &&
    typeof HTMLDialogElement === 'function' &&
    typeof dialog.showModal == 'function'
  ) {
    return true;
  }
  return false;
}

function execute_call_back(call_back) {
  if (!!call_back && typeof call_back == 'function') {
    call_back();
  }
}

function open_modal_dialog({
  dialog_element = null,
  open_call_back = null,
  native_dialog_supported = false,
  dialog_polyfill_mask = null,
  non_modal_dialog_as_modal = false,
} = {}) {
  return function({ call_back = null, initiating_element = undefined } = {}) {
    set_initiating_element(initiating_element);
    if (native_dialog_supported) {
      if (non_modal_dialog_as_modal) {
        dialog_polyfill_mask.classList.add('dialog-polyfill-mask--visible');
        set_sibling_elements_to_inert(dialog_element);
        dialog_element.show();
      } else {
        dialog_element.showModal();
      }
    } else {
      dialog_polyfill_mask.classList.add('dialog-polyfill-mask--visible');
      set_sibling_elements_to_inert(dialog_element);
      dialog_element.classList.add('dialog-polyfill--open');
      dialog_element.setAttribute('open', true);
    }
    execute_call_back(open_call_back);
    execute_call_back(call_back);
    freeze_dcoument();
  };
}

function close_modal_dialog({
  dialog_element = null,
  close_call_back = null,
  native_dialog_supported = false,
  dialog_polyfill_mask = null,
  non_modal_dialog_as_modal = false,
} = {}) {
  const close_form = dialog_element.querySelector('form[method="dialog"]');
  function bind_close_to_escape(event) {
    if (event.key != 'Escape') {
      return false;
    }
    if (!native_dialog_supported) {
      return close_the_funky_dialog();
    }
    close_the_dialog();
    return dialog_element.close();
  }
  function close_the_dialog() {
    unfreeze_document();
    if (non_modal_dialog_as_modal && dialog_polyfill_mask) {
      unset_sibling_elements_to_inert(dialog_element);
      dialog_polyfill_mask.classList.remove('dialog-polyfill-mask--visible');
    }
    restore_focus_to_initiating_element();
    execute_call_back(close_call_back);
  }

  function close_the_funky_dialog() {
    dialog_polyfill_mask.classList.remove('dialog-polyfill-mask--visible');
    dialog_element.classList.remove('dialog-polyfill--open');
    dialog_element.removeAttribute('open', true);
    unset_sibling_elements_to_inert(dialog_element);
    close_the_dialog();
  }

  function enable_close_on_escape() {
    if (native_dialog_supported && !non_modal_dialog_as_modal) {
      return true;
    }
    return document.body.addEventListener('keydown', bind_close_to_escape);
  }
  if (!native_dialog_supported || non_modal_dialog_as_modal) {
    enable_close_on_escape();
  }
  if (native_dialog_supported) {
    dialog_element.addEventListener('close', () => {
      close_the_dialog();
    });
  } else {
    enable_close_on_escape();
    if (close_form) {
      close_form.addEventListener('submit', (event) => {
        event.preventDefault();
        close_the_funky_dialog();
      });
    }
  }

  return function({ call_back = null } = {}) {
    close_the_dialog();
    execute_call_back(call_back);
  };
}

function set_up_modal_dialog({
  dialog_element = null,
  open_call_back = null,
  close_call_back = null,
  non_modal_dialog_as_modal = false,
  test_mode = false,
} = {}) {
  if (test_mode) {
    is_in_test_mode = true;
  }
  if (!(dialog_element instanceof Element)) {
    throw new TypeError(
      `dialog_element is not an valid html Element: it's value is ${dialog_element}.`,
    );
  }
  const dialog_element_is_supported = check_if_dialog_element_is_supported(
    dialog_element,
  );
  if (!dialog_element_is_supported) {
    add_attributes_to_unsupported_dialog(dialog_element);
  } else if (non_modal_dialog_as_modal) {
    dialog_element.setAttribute('aria-modal', true);
  }
  const dialog_polyfill_mask =
    dialog_element_is_supported && !non_modal_dialog_as_modal
      ? null
      : create_dialog_polyfill_mask();
  return {
    open_modal_dialog: open_modal_dialog({
      dialog_element,
      open_call_back,
      native_dialog_supported: dialog_element_is_supported,
      dialog_polyfill_mask,
      non_modal_dialog_as_modal,
    }),
    close_modal_dialog: close_modal_dialog({
      dialog_element,
      close_call_back,
      native_dialog_supported: dialog_element_is_supported,
      dialog_polyfill_mask,
      non_modal_dialog_as_modal,
    }),
    is_native_dialog_supported: dialog_element_is_supported,
  };
}

export default set_up_modal_dialog;
