import get from '../../../helpers/data_layer/get';

function getSDK() {
  return new Promise((resolve, reject) => {
    if (!window.FB) {
      resolve(null);
    }
    resolve(window.FB);
  });
}

function loadSDK(language) {
  return new Promise((resolve, reject) => {
    if (!window.FB) {
      createFbScriptTag(language, reject);
    }
    const sdkTimeout = setTimeout(() => {
      reject(new Error('Loading Facebook SDK timed out (30s).'));
    }, 30000);
    window.fbAsyncInit = () => {
      clearTimeout(sdkTimeout);
      try {
        initialiseApp(window.FB);
        resolve(window.FB);
      } catch (error) {
        reject(error);
      }
    };
  });
}

function createFbScriptTag(language, reject) {
  const scriptTag = document.createElement('script');
  scriptTag.src = `https://connect.facebook.net/${language}/sdk.js`;
  scriptTag.id = 'facebook-jssdk';
  scriptTag.onerror = () => reject(new Error('FB SDK failed to load'));
  return document.getElementsByTagName('script')[0].append(scriptTag);
}

function initialiseApp(FB) {
  return FB.init({
    // need the app id from config
    appId: get('config.facebook.app_id'),
    cookie: true,
    xfbml: true,
    version: 'v3.3',
  });
}

function getLoginStatus(FB) {
  return new Promise((resolve, reject) => {
    FB.getLoginStatus((response) => resolve(response), true);
  });
}

function login(FB) {
  return new Promise((resolve, reject) => {
    FB.login(
      (response) => {
        if (response.authResponse) {
          return resolve(response);
        }
        return reject(
          Error('User cancelled login or did not fully authorize.'),
        );
      },
      {
        scope: 'email,user_birthday,user_gender',
        auth_type: 'rerequest',
      },
    );
  });
}

export default {
  loadSDK,
  initialiseApp,
  login,
  getSDK,
  getLoginStatus,
};
